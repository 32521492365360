import React from "react";
import { graphql } from "gatsby";

import {
	Box,
	Container,
	Card,
	CardHeader,
	CardActionArea,
	Grid
} from "@material-ui/core";
import { Link } from "@runly/gatsby-theme";

import Img from "gatsby-image";

import Headline from "../hero/headline";
import CtaSection from "../cta";

import Layout from "../layout";
import Seo from "../seo";

const BlogPage = ({
	location,
	data: {
		site: {
			siteMetadata: { channelId }
		},
		youtube: { videos }
	}
}) => (
	<Layout location={location} disableFooterMargin>
		<Seo
			title="Explainer Videos and Tutorials"
			description="Explainer videos and tutorials to help you get started with Runly quickly!"
		/>

		<Box clone pt={4} pb={20}>
			<Container maxWidth="lg">
				<Headline title="Explainer Videos and Tutorials">
					Get started with Runly quickly with our explainer videos and
					tutorials.{" "}
					<Link href={`https://www.youtube.com/channel/${channelId}`}>
						Subscribe on YouTube
					</Link>{" "}
					to be updated when we upload new videos!
				</Headline>
			</Container>
		</Box>

		<CtaSection pt={25} pb={10} backgroundColor="paper">
			<Box clone pt={4} pb={20}>
				<Container maxWidth="lg">
					<Grid container spacing={3}>
						{videos.map(post => (
							<Grid key={post.slug} item xs={12} md={6}>
								<VideoCard {...post} />
							</Grid>
						))}
					</Grid>
				</Container>
			</Box>
		</CtaSection>
	</Layout>
);

const VideoCard = ({ slug, cover, date, dateFormatted, title }) => (
	<Card>
		<CardActionArea
			component={props => <Link {...props} underline="none" />}
			href={`https://youtu.be/${slug}`}
		>
			{cover ? (
				<Img fluid={cover.img.fluid} style={{ maxHeight: 300 }} />
			) : null}

			<CardHeader
				title={title}
				subheader={<time dateTime={date}>Published on {dateFormatted}</time>}
			/>
		</CardActionArea>
	</Card>
);

export default BlogPage;

export const blogQuery = graphql`
	query VideoPageQuery {
		site {
			siteMetadata {
				channelId: youtube
			}
		}
		youtube: allYoutubeVideo(
			sort: { fields: publishedAt }
			filter: { privacyStatus: { eq: "public" } }
		) {
			videos: nodes {
				slug: videoId
				title
				date: publishedAt
				dateFormatted: publishedAt(formatString: "MMMM Do, YYYY")
				cover: localThumbnail {
					img: childImageSharp {
						fluid(maxWidth: 1200) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
	}
`;
